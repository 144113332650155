
import styled from 'styled-components'

const ContentBlock = styled.section`
    background-color: rgb(255, 255, 255);
    padding: 32px 0px;

    @media (min-width:576px) {
        padding: 64px 0px;
    } 
`;

export default ContentBlock