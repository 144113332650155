import React from 'react'
import styled from 'styled-components'

const Deck = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    margin-top: 32px;

    @media only screen and (min-width:62em) {
        justify-content: center;
    }
`

const CardDeck = props => {
  return <Deck>{props.children}</Deck>
}

export default CardDeck