import React from 'react'
import styled from 'styled-components'
import Container from './container'
import WorldIcon from "../../assets/world.svg"
import BadgeIcon from "../../assets/badge.svg"
import ProcessIcon from "../../assets/process.svg"
import EuroIcon from "../../assets/euro.svg"

const AdvantagesSection = styled.section`
    background-color: rgb(45, 47, 61);
    margin-bottom: 0px;
    margin-top: 32px;
    padding-top: 64px;
    padding-bottom: 64px;
    @media only screen and (max-width:768px) {
        margin-bottom: 0px;
    }
`;

const HeaderWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    justify-content: center;
    text-align: center;

    @media only screen and (min-width:62em) {
        justify-content: center;
    }
`;

const HeaderContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    justify-content: center;

    @media only screen and (min-width:62em) {
        justify-content: center;
    }
`;

const Header = styled.h2`
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0 0 32px 0;

    @media (min-width:576px) {
        font-size: 36px;
    }
`;
const AdvantagesContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
`;

const Advantage = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: auto;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    max-width: 100%;
    margin: 32px auto;

    @media only screen and (min-width:768px) {
        max-width: 576px;
        margin: 40px auto;
        padding: 0px 40px;
    }
    @media only screen and (min-width:62em) {
        flex-basis: 33.3333%;
        max-width: 33.3333%;
        display: block;
    }
    p {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 300;
        line-height: 1.6;
        text-align: center;
        margin: 0px auto;
        max-width: 768px;

        @media only screen and (min-width:576px) {
            font-size: 18px;
        }
    }
    h4 {
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;
        margin: 0px 0px 16px;
        text-align: center;
        @media only screen and (min-width:576px) {
            font-size: 18px;
        }
    }
`;
const AdvantageIcon = styled.div`
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    padding: 0px 0px 24px;
`;
const AdvantageIconWrapper = styled.span`
    display: flex;
    color: rgb(61, 220, 151);
    font-size: 42px;
`;


const BerlitzAdvantages = props => (
    <AdvantagesSection>
        <Container>
            <HeaderWrapper>
                <HeaderContainer>
                    <Header>{props.advantagesheader}</Header>
                </HeaderContainer>
            </HeaderWrapper>
            <AdvantagesContainer>
            {props.advantages.map(item => (
                <Advantage>
                    <AdvantageIcon>
                        <AdvantageIconWrapper>
                        {item.icon === "WorldIcon" &&
                            <WorldIcon />
                        }
                        {item.icon === "BadgeIcon" &&
                            <BadgeIcon />
                        }
                        {item.icon === "ProcessIcon" &&
                            <ProcessIcon />
                        }
                        {item.icon === "EuroIcon" &&
                            <EuroIcon />
                        }
                        </AdvantageIconWrapper>
                    </AdvantageIcon>
                    <h4>{item.header}</h4>
                    <p>{item.text}</p>
                </Advantage>
            ))}
            </AdvantagesContainer>
        </Container>
    </AdvantagesSection>
)

export default BerlitzAdvantages

