import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/mainseo'
import Layout from '../components/Layout'
import PageBannerOG from '../components/pagebanner/pagebanner'
import MainNav from '../components/navigation/mainnav'
import PageBannerMessage from '../components/pagebanner/pagebannermessage'
import Container from '../components/container'
import Card from '../components/cards/linkcard'
import CardDeck from '../components/cards/carddeck'
import PageContact from '../components/forms/pagecontact-sf'
import FeatureCard from '../components/cards/featurecard'
import BerlitzAdvantages from '../components/berlitzadvantages'
import CenteringDiv from '../components/centeringdiv'
import CenteredContentContainer from '../components/centeredcontentcontainer' 
import ContentBlockBackground from '../components/contentblockbackground'
import ContentContainer from '../components/contentcontainer'
import ContentBlock from '../components/contentblock'

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  var delink = "de";
  var enlink = "en";
  const metaUrlalt="https://www.berlitz-augsburg.de/"
  const metaUrl="https://www.berlitz-augsburg.de"
  if (frontmatter.language === "de"){
    delink = metaUrlalt;
    enlink = `${metaUrlalt}${frontmatter.seo.altlanguagelink}`;
  } else if (frontmatter.language === "en") {
    delink = metaUrlalt;
    enlink = `${metaUrl}${data.markdownRemark.fields.slug}`
  }

  return (
    <Layout currentlang={frontmatter.language} langlinkde={delink} langlinken={enlink}>
      <Seo language={frontmatter.language}
           title={frontmatter.seo.metatitle}
           imgtitle={frontmatter.imagetitle}
           imgtitleen={frontmatter.imagetitle}
           description={frontmatter.seo.metadescription}
           pagetype={frontmatter.templateKey}
           pageslug={data.markdownRemark.fields.slug}
           altlanglink={frontmatter.seo.altlanguagelink}
           hreflangalt={frontmatter.seo.hreflangalt}
      />
      {frontmatter.language === "de" &&
        <PageBannerOG title={frontmatter.title} alt={frontmatter.displaytitle} isHome={frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={frontmatter.language}/>
          <PageBannerMessage supertitle={frontmatter.supertitle} pageTitle={frontmatter.displaytitle} pageSubtitle={frontmatter.pagesubtitle} isHome={frontmatter.ishomepage} currentlang={frontmatter.language}/>
        </PageBannerOG>
      }
      {frontmatter.language === "en" &&
        <PageBannerOG title={frontmatter.imagetitle} alt={frontmatter.displaytitle} isHome={frontmatter.ishomepage}>
          <MainNav langlinkde={delink} langlinken={enlink} currentlang={frontmatter.language}/>
          <PageBannerMessage supertitle={frontmatter.supertitle} pageTitle={frontmatter.displaytitle} pageSubtitle={frontmatter.pagesubtitle} isHome={frontmatter.ishomepage} currentlang={frontmatter.language}/>
        </PageBannerOG>
      }
      <section role="main">
      <ContentBlockBackground>
      <Container>
          <CenteringDiv>
            <CenteredContentContainer>
              <h2>{frontmatter.cardsintro.heading}</h2>
              <p>{frontmatter.cardsintro.text}</p>
            </CenteredContentContainer>
          </CenteringDiv>
          <CardDeck>
            {frontmatter.cards.map(item => (
              <Card cardtitle={item.cardtitle}
                    url={item.link}
                    button={item.button}
                    cardimg={item.image.childImageSharp.fixed}
                    currentlanguage={frontmatter.language}
              />
            ))}
          </CardDeck>
        </Container>
        </ContentBlockBackground>
        {frontmatter.pagebody &&
          <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}/>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        }
        <ContentBlockBackground>
          <Container>
            <FeatureCard cardtitle={frontmatter.featurecard.cardtitle}
                         cardwithlink={frontmatter.cardwithlink}
                         cardtext={frontmatter.featurecard.cardtext}
                         cardbutton={frontmatter.featurecard.cardbutton}
                         cardlink={frontmatter.featurecard.cardlink}
                         cardimage={frontmatter.featurecard.cardimage.childImageSharp.fixed}
            />
          </Container>
        </ContentBlockBackground>
        <BerlitzAdvantages advantagesheader={frontmatter.advantagesheader}
                           advantages={frontmatter.advantages}
                           currentlang={frontmatter.language} 
        />
        <div id="rundgang"></div>
            <ContentBlock>
            <Container>
              <CenteringDiv>
                <ContentContainer>
                  {frontmatter.language === "de" &&
                  <h2 style="margin-bottom: 32px">Virtueller Rundgang durch die Berlitz Sprachschule in Augsburg</h2>
                  }
                  {frontmatter.language === "en" &&
                  <h2>Virtual tour of the Berlitz Language School in Augsburg</h2>
                  }
                  <iframe src="https://www.google.com/maps/embed?pb=!1m0!4v1499078248101!6m8!1m7!1sF%3A-8lxL4BPlzQo%2FWT5XhMztPrI%2FAAAAAAAB_gk%2FVJMWvODQFL4HN91TU5QaLQlqGzSj-XzvACLIB!2m2!1d48.370366281941!2d10.896204314733!3f211.66210628786914!4f3.517994494391388!5f0.7820865974627469" width="100%" height="600" frameborder="0" style="border:0" allowfullscreen>
                  </iframe>
                </ContentContainer>
              </CenteringDiv>
            </Container>
          </ContentBlock>
        {frontmatter.language === "de" &&
            <PageContact currentlang={frontmatter.language}
                         leadsource="/"
                         pagetype={frontmatter.pagetype} 
            />
        }
        {frontmatter.language === "en" &&
            <PageContact currentlang={frontmatter.language}
                         leadsource="/en/"
                         pagetype={frontmatter.pagetype} 
            />
        }
      </section>
    </Layout>
  )
}


export default IndexPage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        templateKey
        pagetype
        ishomepage
        title
        imagetitle
        displaytitle
        seo {
          metatitle
          metadescription
          altlanguagelink
          hreflangalt
        }
        language
        heading
        pagesubtitle
        cardwithlink
        featurecard {
          cardtitle
          cardtext
          cardbutton
          cardlink
          cardimage {
            childImageSharp {
              fixed(width: 500, height: 500, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        pagebody
        advantagesheader
        advantages {
          heading
          icon
          text
        }
        cardsintro {
          heading
          text
        }
        cards {
          image {
            childImageSharp {
              fixed(width: 480, height: 320, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          link
          cardtitle
          button
        }
      }
    }
  }
`
