import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const CardContainer = styled.div`

  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

const CardRow = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0;
  padding-left: 0;


  @media only screen and (min-width:0em) {
    flex-basis: 100%;
    max-width: 100%;
    display: block;
  }

  @media only screen and (min-width:62em) {
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
    display: block;
  }
`;

const CardWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 200px;

  @media (min-width:768px) {
    flex-direction: row;
    min-height: 483px;
  }
`;

const CardImageWrapper = styled.div`
@supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
  flex: 1 1 0%;
}

@media (min-width:768px) {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
}
`;

const FeatureCardImg = styled(Img)`
max-height: 100% !important;
height: 100% !important;
width: 100% !important;

  @media (max-width:768px) {
  min-height: 160px;
  }
`;

const CardContent = styled.div`
  padding: 24px;
  text-align: left;

  @supports (display:-webkit-box) or (display:-webkit-flex) or (display:-ms-flexbox) or (display:flex) {
    flex: 1 1 0%;
  }

  @media (min-width:768px) {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    justify-content: center;
    margin: 32px 0;
    padding: 48px;
  }
`;

const CardP = styled.p`
  margin-bottom: 34px;
`;

const CardLink = styled(Link)`
  min-width: 118px;
  background: none;
  background-color: #004FEF;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  width: auto;
  color: #FFFFFF;
  position: relative;
  text-transform: none;

  :hover,
  :active,
  :focus {
    background-color: rgb(25, 96, 240);
    border: none;
    color: #FFFFFF;
  }

  :hover,
  :active,
  :focus {

  }

`;

const FeatureCard = props => (
<CardContainer>
  <CardRow>
    <CardWrapper>
        <CardImageWrapper>
          <FeatureCardImg fixed={props.cardimage} 
               alt={props.cardtitle}
               loading={"eager"}
               fadein="false"
          />
        </CardImageWrapper>
        <CardContent>
          <h2>{props.cardtitle}</h2>
          <CardP>{props.cardtext}</CardP>
          {props.cardlink  &&
          <div>
            <CardLink to={props.cardlink}>{props.cardbutton}</CardLink>
          </div>
          }
        </CardContent>
    </CardWrapper>
  </CardRow>
</CardContainer>

);
export default FeatureCard; 
